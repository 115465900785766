<template>
  <CModal
    :show.sync="show"
    :close-on-backdrop="false"
    :centered="true"
    size="lg"
    color="primary"
  >
    <input type="file" ref="fileInput" accept="image/jpeg,image/png" @change="handleImage" hidden/>
    <CAlert :show.sync="dismissCountDown" color="danger" fade>{{ notification }}</CAlert>
    <CCard>
      <CCardBody v-if="step === 0">
        <CInput
          label="Name"
          placeholder="Organization name"
          horizontal
          :value.sync="organization_updated.name"
        />
        <CSelect
          label="Max user"
          horizontal
          :options="$resource.MAX_USER_OPTS"
          :value.sync="organization_updated.max_user"
        />
        <CSelect
          label="Max device"
          horizontal
          :options="$resource.MAX_DEVICE_OPTS"
          :value.sync="organization_updated.max_device"
        />
      </CCardBody>
      <CCardBody v-if="step === 1">
        <el-form label-width="25%" @submit.native.prevent>
          <el-form-item v-if="show_crop_ui" label="Logo Image">
            <div class="mb-1">
              <Jcrop
                :src="image_uri"
                @update="onCrop"
                :rect="rect"
                :options="options"
              />
            </div>
            <canvas ref="cropCanvas" class="border"></canvas>
          </el-form-item>
          <el-form-item v-else label="Logo Image">
            <div class="mb-1">
              <el-button type="primary" size="small" @click="onUpload">Select</el-button>
            </div>
            <img v-if="organization_updated.logo" :src="organization_updated.logo">
          </el-form-item>
        </el-form>
      </CCardBody>
    </CCard>
    <template #header>
      <h6 class="modal-title">New Organization  {{" - STEP ("+(step+1)+"/2)"}}</h6>
      <CButtonClose @click="show = false" class="text-white"/>
    </template>
    <template #footer>
      <CButton v-if="step===0" @click="onCancel" color="secondary">Cancel</CButton>
      <CButton v-if="step===0" @click="onNext" color="primary">Next</CButton>
      <CButton v-if="step===1" @click="onPrev" color="secondary">Prev</CButton>
      <CButton v-if="step===1" @click="onSubmit" color="primary">Submit</CButton>
    </template>
  </CModal>
</template>

<script>
import { Jcrop } from 'vue-jcrop';
import utility from '@/utility.js'

export default {
  name: 'OrganizationForm',
  components: {
    Jcrop
  },
  props: {
    organization: {type: Object, default: null}
  },
  data() {
    return {
      show: false,

      organization_updated: {
        id: null,
        name: null,
        max_user: null,
        max_device: null,
        logo: null
      },

      show_crop_ui: false,
      rect: [0, 0, 290, 70],
      image_uri: null,
      position: {},
      options: {
        handles: ['se'],
        aspectRatio: 290 / 70
      },
      image_crop_uri: null,
      image_blob: null,

      notification: '',
      dismissCountDown: 0,

      step: 0
    }
  },
  methods: {
    open() {
      this.onCancel();
      this.initialize();
      this.show = true;
    },
    initialize() {
      if (this.organization) {
        this.organization_updated = {
          id: this.organization.id,
          name: this.organization.name,
          max_user: this.organization.max_user,
          max_device: this.organization.max_device,
          logo: this.organization.logo
        };
      } else {
        this.organization_updated = {
          id: null,
          name: null,
          max_user: 10,
          max_device: 10,
          logo: null
        };
      }
    },
    onCancel() {
      this.show = false;

      this.name = null;
      this.logo = null;
      this.max_user = null;
      this.max_device = null;

      this.show_crop_ui = false;
      this.rect = [0, 0, 290, 70];
      this.orig_image = null;
      this.image_uri = null;
      this.position = {};
      this.options = {
        handles: ['se'],
        aspectRatio: 290 / 70
      };
      this.image_crop_uri = null;
      this.image_blob = null;
      this.$refs.fileInput.value = null;
      this.step = 0;
    },
    validateAndWarning() {
      if (!this.organization_updated.name) {
        this.notification = 'Name is required';
        this.dismissCountDown = 3;
        return true;
      }
      if (!this.organization_updated.max_user) {
        this.notification = 'The number of maximum user is required';
        this.dismissCountDown = 3;
        return true;
      }
      if (!this.organization_updated.max_device) {
        this.notification = 'The number of maximum device is required';
        this.dismissCountDown = 3;
        return true;
      }
      return null;
    },
    onSubmit() {
      if (this.validateAndWarning()) {
        return;
      }

      let blob = null;
      if (this.image_crop_uri) {
        blob = utility.DataURI2Blob(this.image_crop_uri);
      }
      const fd = new FormData;
      fd.append("name", this.organization_updated.name);
      fd.append("max_user", this.organization_updated.max_user);
      fd.append("max_device", this.organization_updated.max_device);
      if (blob) {
        fd.append("image", blob, "logo_145x35.jpg");
      }

      this.$emit('payload', fd, this.organization_updated.id);
      this.onCancel();
    },
    onNext() {
      if(this.validateAndWarning()) {
        return;
      }
      this.step = 1;
    },
    onPrev() {
      this.step = 0;
    },

    cropImage(imageObj, x, y, w, h) {
      utility.EraseCanvas(this.$refs.cropCanvas);
      const canvas = this.$refs.cropCanvas;
      canvas.width = w;
      canvas.height = h;
      const ctx = canvas.getContext('2d');
      ctx.save();
      ctx.drawImage(imageObj, x,y,w,h, 0,0,w,h);
      ctx.restore();
      this.image_crop_uri = canvas.toDataURL('image/jpeg', 1.0);
    },
    handleImage(e) {
      if (e.target.files.length === 0) {
        return;
      }
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const reader = new FileReader;
      const img = new Image;

      reader.onload = event => {
        img.onload = _ => {
          // console.log(img.width, img.height);
          if (img.width < 290 || img.height < 70) {
            this.notification = 'Image is too small. Minimum size is 290x70.';
            this.dismissCountDown = 3;
            return;
          }
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          this.image_uri = utility.ContainImageSize(img, 540, 400);
          this.show_crop_ui = true;
        }
        img.src = event.target.result;
      }
      reader.readAsDataURL(e.target.files[0]);
    },
    onUpload() {
      this.$refs.fileInput.click();
    },
    onCrop(e) {
      this.position = e.sel.round();
      const img = new Image;
      img.onload = _ => {
        const position = this.position;
        this.cropImage(img, position.x, position.y, position.w, position.h);
      }
      img.src = this.image_uri;
    }
  }
}
</script>

<style lang="scss" scoped>
.normal-input {
  width: 80%;
}
.short-input {
  width: 40%;
}
</style>